* {
  color: var(--color);
  user-select: none;
  font-family: Courier New, Courier, monospace;
}

body {
  --background-color: white;
  --color: black;
  background-color: var(--background-color);
}

:root {
  font-size: 100%;
}

@media (min-resolution: 100dpi) {
  :root {
    font-size: 1.5em;
  }
}

a {
  cursor: pointer;
  text-decoration: underline;
}

button {
  border: thin solid var(--color);
  cursor: pointer;
  padding: .5rem;
}

select {
  font-size: inherit;
  border: thin solid var(--color);
  cursor: pointer;
  padding: 0;
}

button:hover {
  background-color: gray;
}

dialog, select, button {
  background-color: var(--background-color);
  color: var(--color);
}

dialog .dismiss {
  position: absolute;
  top: 0;
  right: .1em;
}

dialog .dismiss:before {
  content: "✖";
}

#background {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: -10;
  position: absolute;
  top: 0;
  left: 0;
}

#background.light {
  background-color: #0000001a;
}

#background.dark {
  background-color: #000c;
}

#background.dark ~ aside {
  --color: white;
}

#background.black {
  background-color: #000;
}

#debug a {
  color: #0000;
}

#debug a:hover {
  color: unset;
}

body.darkMode {
  --background-color: black;
  --color: white;
}

#background.dark + svg {
  opacity: .5;
  --background-color: #333;
}

.darkMode #background.dark + svg {
  --background-color: black;
}

/*# sourceMappingURL=index.500c8991.css.map */
