* {
  font-family: 'Courier New', Courier, monospace;
  color: var(--color);
  user-select: none;
}

body {
  --background-color: white;
  --color: black;
  background-color: var(--background-color);
}

:root {
  font-size: 100%;
}

@media (min-resolution: 100dpi) {
  :root {
    font-size: 1.5em;
  }
}

a {
  text-decoration: underline;
  cursor: pointer;
}

button {
  padding: 0.5rem;
  border: thin solid var(--color);
  cursor: pointer;
}

select {
  font-size: inherit;
  padding: 0em;
  border: thin solid var(--color);
  cursor: pointer;
}

button:hover {
  background-color: grey;
}

dialog,
select,
button {
  background-color: var(--background-color);
  color: var(--color);
}

dialog .dismiss {
  position: absolute;
  top: 0;
  right: 0.1em;
}
dialog .dismiss::before {
  content: '✖';
}

#background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: -10;
}
#background.light {
  background-color: rgba(0, 0, 0, 0.1);
}

#background.dark {
  background-color: rgba(0, 0, 0, 0.8);
}

#background.dark ~ aside {
  --color: white;
}

#background.black {
  background-color: black;
}

#debug a {
  color: transparent;
}

#debug a:hover {
  color: unset;
}

/* Dark mode */
body.darkMode {
  --background-color: black;
  --color: white;
}

#background.dark + svg {
  opacity: 0.5;
  --background-color: #333;
}

.darkMode #background.dark + svg {
  --background-color: black;
}
